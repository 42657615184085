import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getGuestData } from "../data/getGuestData";
import { stripNonDigits } from "../utils/stripNonDigits";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // console.log(firebaseUser);
        const data = await getGuestData(
          stripNonDigits(firebaseUser.phoneNumber)
        );
        if (!data) {
          auth.signOut();
          return;
        } else {
          setUser(firebaseUser);
        }
      } else {
        setUser(null);
      }
    });
  }, [auth]);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
