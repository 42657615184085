import React, { useState } from "react";
import styled from "styled-components";
import { getAuth } from "firebase/auth";
import { icons } from "../../icons";
import { colorPrimary, colorSecondary, colorText, fontMain } from "../../theme";

const MenuButton = styled.div`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    svg {
      filter: opacity(75%);
      transition: 200ms;
    }
  }
  svg {
    fill: ${colorSecondary};
  }
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const MenuBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const LeftMenu = styled.div`
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  min-width: 250px;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const NavItem = styled.a`
  cursor: pointer;
  width: 100%;
  border-left: 8px solid
    ${({ active }) => (active ? colorSecondary : "transparent")};
  min-height: 48px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: ${fontMain};
  :hover {
    background: #eee;
    transition: 200ms;
  }
  ${({ active }) =>
    active &&
    `
    border-left: 4px solid ${colorPrimary};
    color: ${colorText};
  `}
  ${({ end }) =>
    end &&
    `
    position: absolute;
    bottom: 0;
    left: 0;
  `}
`;

const MobileMenu = ({ currentPage, setCurrentPage }) => {
  const [open, setOpen] = useState(false);

  const handleNav = (page) => {
    setOpen(false);
    setCurrentPage(page);
  };

  const handleLogout = () => {
    const auth = getAuth();

    if (auth.currentUser) {
      auth.signOut();
    }
  };

  return !open ? (
    <MenuButton onClick={() => setOpen(true)}>{icons.hamburger}</MenuButton>
  ) : (
    <>
      <MenuBackground onClick={() => setOpen(false)} />
      <LeftMenu>
        <NavItem
          active={currentPage === "home"}
          onClick={() => handleNav("home")}
        >
          Home
        </NavItem>
        <NavItem
          active={currentPage === "events"}
          onClick={() => handleNav("events")}
        >
          Events
        </NavItem>
        {/* <NavItem
          active={currentPage === "accommodations"}
          onClick={() => handleNav("accommodations")}
        >
          Accommodations
        </NavItem> */}
        <NavItem
          active={currentPage === "photos"}
          onClick={() => handleNav("photos")}
        >
          Photos
        </NavItem>
        <NavItem
          active={currentPage === "ourStory"}
          onClick={() => handleNav("ourStory")}
        >
          Our Story
        </NavItem>
        <NavItem
          active={currentPage === "rsvp"}
          onClick={() => handleNav("rsvp")}
        >
          RSVP
        </NavItem>
        <NavItem end onClick={() => handleLogout()}>
          Logout
        </NavItem>
      </LeftMenu>
    </>
  );
};

export default MobileMenu;
