import React, { useState, useEffect } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import uuid from "react-uuid";
import styled from "styled-components";
import { colorSecondary, fontMain } from "../theme";
import logo from "../images/login-logo.png";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 250px;
  max-width: 75%;
  margin-bottom: 5%;
`;

const Explanation = styled.p`
  font-family: ${fontMain};
  color: ${colorSecondary};
  margin-bottom: 15%;
`;

const RecordingDot = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${({ recording }) =>
    recording ? "rgba(255,0,0,0.8);" : "rgba(255,0,0,0.1);"};
`;

const MainBtn = styled.div`
  cursor: pointer;
  border-radius: 5px;
  height: 44px;
  max-width: 90vw;
  min-width: 250px;
  background: ${colorSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontMain};
  margin: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SecondaryBtn = styled(MainBtn)`
  color: ${colorSecondary};
  background: transparent;
`;

const Recorder = () => {
  const [file, setFile] = useState(null);
  const [recording, setRecording] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));

  const [timer, setTimer] = useState(30);

  const upload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `/audioClips/${uuid()}`);
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      setFile(null);
      alert("Message sent! Thanks!");
      setUploading(false);
    });
  };

  const playFile = () => {
    const player = new Audio(URL.createObjectURL(file));
    player.play();
  };

  const stopRecording = async () => {
    setRecording(false);
    // Once you are done singing your best song, stop and get the mp3.
    await recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // do what ever you want with buffer and blob
        // Example: Create a mp3 file and play
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        setFile(file);
      })
      .catch((e) => {
        setFile(null);
        alert("We could not retrieve your message");
        console.log(e);
      });
  };

  const startRecording = async () => {
    // Start recording. Browser will request permission to use your microphone.
    await recorder
      .start()
      .then(() => {
        setRecording(true);
        // something else
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (!recording) {
      setTimer(30);
      return;
    }

    let time = 30;

    const countdown = setInterval(() => {
      setTimer(time);
      time--;
    }, 1000);

    return () => clearInterval(countdown);
  }, [recording]);

  useEffect(() => {
    if (timer === 0) {
      stopRecording();
    }
  }, [timer]);

  return (
    <Wrapper>
      <Image src={logo} />
      <Explanation>
        <strong>Welcome to our digital voice guestbook!</strong>
        <br />
        <br />
        <ul>
          <li>Click record</li>
          <li>Say your message into your phone</li>
          <li>Stop recording</li>
          <li>Send to the bride and groom!</li>
        </ul>
      </Explanation>
      <RecordingDot recording={recording}>{timer}s</RecordingDot>
      {!recording && !file && (
        <MainBtn onClick={() => startRecording()}>Record your message</MainBtn>
      )}
      {recording && (
        <MainBtn onClick={() => stopRecording()}>Stop Recording</MainBtn>
      )}
      {file && (
        <>
          <MainBtn onClick={() => upload()}>
            {uploading ? "Sending..." : "Send to Chance & Rutu"}
          </MainBtn>
          <SecondaryBtn onClick={() => playFile()}>
            Play Your Message
          </SecondaryBtn>
          <SecondaryBtn onClick={() => setFile(null)}>Delete</SecondaryBtn>
        </>
      )}
    </Wrapper>
  );
};

export default Recorder;
