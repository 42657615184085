import React, { useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { setGuestData } from "../../data/setGuestData";
import { getGuestData } from "../../data/getGuestData";
import { stripNonDigits } from "../../utils/stripNonDigits";
import { countryCodes } from "../../data/countryCodes";

const AdminWrapper = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex: 1;
`;

const InputWrap = styled.div`
  display: flex;
  margin: 5px 0;
`;

const CountrySelect = styled.select`
  height: 44px;
  border-radius: 5px 0 0 5px;
  border: none;
  background: #859c83;
  color: white;
  padding-left: 5px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Masked = styled(InputMask)`
  height: 44px;
  border-radius: 0 5px 5px 0;
  border: none;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  text-align: center;
  :focus {
    outline: 2px solid #859c83;
  }
`;

const Button = styled.button`
  height: 30px;
  min-width: 150px;
  margin: 10px 0;
  background: #333;
  color: white;
  cursor: pointer;
  :hover {
    filter: brightness(90%);
  }
  ${({ color }) =>
    color
      ? `
    background: ${color};
    border: none;
    color: white;
  `
      : []}
  ${({ disabled }) =>
    disabled
      ? `
    filter: opacity(50%);
    cursor: not-allowed;
    :hover {
      filter: opacity(50%);
    }
  `
      : []}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  border-bottom: 1px solid #bbb;
  padding: 10px 0;
  margin: 10px 0;
`;

const TextInput = styled.input`
  height: 20px;
`;

const EventName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
`;

const EventWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.p`
  margin: 20px;
  font-size: 18px;
`;

const AddPage = () => {
  const guest = {
    name: "",
    events: [
      {
        eventName: "Ganesh Pooja & Haldi",
        eventDate: "Sept 1, 2022",
        going: "---",
        invited: false,
      },
      {
        eventName: "Mehendi & Sangeet",
        eventDate: "Sept 1, 2022",
        going: "---",
        invited: false,
      },
      {
        eventName: "Grah Shantek",
        eventDate: "Sept 2, 2022",
        going: "---",
        invited: false,
      },
      {
        eventName: "Wedding Ceremony",
        eventDate: "Sept 3, 2022",
        going: "---",
        invited: false,
      },
      {
        eventName: "Reception",
        eventDate: "Sept 4, 2022",
        going: "---",
        invited: false,
      },
    ],
  };

  const [phoneNum, setPhoneNum] = useState("");
  const [data, setData] = useState({
    guests: [],
  });
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  const changeCountryCode = (code) => {
    setCountryCode(code);
  };

  const clearAllFields = () => {
    const tempData = { guests: [] };
    const tempNum = "";

    setData({ ...tempData });
    setPhoneNum(tempNum);
  };

  const nameInputHandler = (personPosition, name) => {
    const tempData = data;
    tempData.guests[personPosition].name = name;
    setData({ ...tempData });
  };

  const checkboxHandler = (personPosition, eventPosition) => {
    const tempData = data;
    tempData.guests[personPosition].events[eventPosition].invited =
      !tempData.guests[personPosition].events[eventPosition].invited;
    setData({ ...tempData });
  };

  const toggleAll = (personPosition) => {
    const tempData = data;
    tempData.guests[personPosition].events.forEach((event) => {
      event.invited = !event.invited;
    });
    setData({ ...tempData });
  };

  const addGuest = () => {
    const tempData = data;
    tempData.guests.push(guest);
    setData({ ...tempData });
  };

  const handleSendData = async () => {
    const strippedNum = stripNonDigits(phoneNum);
    if (strippedNum.length !== 10) {
      alert("Please enter valid phone number in format: 1231231234");
      return;
    }

    if (data.guests.length === 0) {
      const dataExists = await getGuestData(
        stripNonDigits(`${countryCode}${phoneNum}`)
      );
      if (dataExists) {
        // console.log(dataExists);
        setData(dataExists);
        return;
      }
    }

    if (data.guests.length === 0) {
      alert("You must have at least one guest per itinerary");
      return;
    }

    const tempData = data;
    tempData.guests = tempData.guests.filter((guest) => guest.name.length > 0);
    setData(tempData);
    // console.log(tempData);

    const result = await setGuestData(`${countryCode}${strippedNum}`, tempData);
    if (result === 200) {
      setMessage("Success");
      clearAllFields();
    } else {
      // console.log(result);
      setMessage("Something went wrong");
    }
  };

  return (
    <AdminWrapper>
      <InputWrap>
        <CountrySelect onChange={(e) => changeCountryCode(e.target.value)}>
          {countryCodes.map(({ flag, code }) => (
            <option value={code} key={flag}>
              {flag} {code}
            </option>
          ))}
        </CountrySelect>
        <Masked
          mask={"(999)-999-9999"}
          alwaysShowMask
          value={phoneNum}
          onChange={(e) => setPhoneNum(e.target.value)}
          autoComplete="tel"
          type="tel"
          name="cell number"
          onFocus={() => setMessage("")}
          disabled={data.guests.length > 0}
        />
      </InputWrap>
      {data.guests.map((person) => (
        <Row>
          <TextInput
            label="name"
            placeholder="name"
            value={person.name}
            onChange={(e) =>
              nameInputHandler(data.guests.indexOf(person), e.target.value)
            }
          />
          {person.events.map((event) => (
            <EventWrap>
              <EventName>{event.eventName}</EventName>
              <input
                type="checkbox"
                checked={event.invited}
                onChange={() =>
                  checkboxHandler(
                    data.guests.indexOf(person),
                    person.events.indexOf(event)
                  )
                }
              />
            </EventWrap>
          ))}
          <button onClick={() => toggleAll(data.guests.indexOf(person))}>
            Toggle All
          </button>
        </Row>
      ))}
      <Button
        disabled={stripNonDigits(phoneNum).length !== 10}
        onClick={() => addGuest()}
      >
        Add Guest
      </Button>
      <Button
        disabled={stripNonDigits(phoneNum).length !== 10}
        color="#859c83"
        onClick={handleSendData}
      >
        {data.guests.length > 0 ? "Send Data" : "Get Data"}
      </Button>
      <Button color="#964d4d" onClick={clearAllFields}>
        Cancel
      </Button>
      <MessageText>{message}</MessageText>
    </AdminWrapper>
  );
};

export default AddPage;
