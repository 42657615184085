import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllGuests } from "../../data/getAllGuests";
import { fontMain, textSizeM, textSizeS } from "../../theme";

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  // margin-top: 100px;
`;

const User = styled.div`
  width: 100vw;
  max-width: 600px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-self: center;
  ${({ index }) =>
    index % 2 &&
    `
    background: rgba(0,0,0,0.1);
  `}
`;

const Header = styled.div`
  width: 100vw;
  max-width: 600px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-self: center;
  // position: fixed;
  top: 0;
`;

const Phone = styled.text`
  font-size: ${textSizeS};
  font-family: ${fontMain};
`;

const Names = styled.text`
  font-size: ${textSizeM};
  font-family: ${fontMain};
`;

const ResponseArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Going = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 75px;
  text-align: center;
  background: ${({ invited }) => (!invited ? "#ffabaf" : "#c2ffc4")};
  ${({ title }) => title && "background: white;"}
`;

const ListAllPage = () => {
  const [data, setData] = useState([]);
  const [ackLength, setAckLength] = useState(0);
  const [noAckLength, setNoAckLength] = useState(0);
  const [haldiYes, setHaldiYes] = useState(0);
  const [sangeetYes, setSangeetYes] = useState(0);
  const [shantekYes, setShantekYes] = useState(0);
  const [ceremonyYes, setCeremonyYes] = useState(0);
  const [receptionYes, setReceptionYes] = useState(0);
  const [ackPeople, setAckPeople] = useState(0);
  const [notAckPeople, setNotAckPeople] = useState(0);
  const [goingPeople, setGoingPeople] = useState([]);

  console.log(data);

  const getData = async () => {
    const guestListData = await getAllGuests();
    // setData(guestListData);

    const acknowledgedData = [...guestListData].filter((invite) =>
      invite.guests.some((guest) =>
        guest.events.some((event) => event.going !== "---")
      )
    );

    setAckLength(acknowledgedData.length);

    let ackPeopleData = 0;
    acknowledgedData.forEach((item) => (ackPeopleData += item.guests.length));
    setAckPeople(ackPeopleData);

    const goingPeopleData = [...guestListData].filter((invite) =>
      invite.guests.some((guest) =>
        guest.events.some((event) => event.going === "Yes")
      )
    );

    const goingPeopleNums = goingPeopleData.map((invite) => invite.phone);

    console.log("### goingPeopleNums: ", goingPeopleNums);

    setGoingPeople(goingPeopleNums);

    const notAcknowledgedData = [...guestListData].filter((invite) =>
      invite.guests.every((guest) =>
        guest.events.every((event) => event.going === "---")
      )
    );

    const someNoReply = [...guestListData].filter((invite) =>
      invite.guests.some((guest) =>
        guest.events.some(
          (event) => event.invited === true && event.going === "---"
        )
      )
    );

    console.log("### someNoReply: ", someNoReply);

    const someNoReplyNums = someNoReply.map((invite) => invite.phone);

    console.log("### someNoReplyNums", someNoReplyNums);

    setNoAckLength(notAcknowledgedData.length);

    let notAckPeopleData = 0;
    notAcknowledgedData.forEach(
      (item) => (notAckPeopleData += item.guests.length)
    );
    setNotAckPeople(notAckPeopleData);

    let haldiNum = [];
    let sangeet = [];
    let shantek = [];
    let ceremony = [];
    let reception = [];

    [...guestListData].forEach((invite) =>
      invite.guests.forEach((guest) =>
        guest.events.forEach((event) => {
          if (event.eventName === "Ganesh Pooja & Haldi") {
            if (event.going === "Yes") {
              haldiNum.push(1);
            }
          }
          if (event.eventName === "Mehendi & Sangeet") {
            if (event.going === "Yes") {
              sangeet.push(1);
            }
          }
          if (event.eventName === "Grah Shantek") {
            if (event.going === "Yes") {
              shantek.push(1);
            }
          }
          if (event.eventName === "Wedding Ceremony") {
            if (event.going === "Yes") {
              ceremony.push(1);
            }
          }
          if (event.eventName === "Reception") {
            if (event.going === "Yes") {
              reception.push(1);
            }
          }
        })
      )
    );

    setHaldiYes(haldiNum.length);
    setSangeetYes(sangeet.length);
    setShantekYes(shantek.length);
    setCeremonyYes(ceremony.length);
    setReceptionYes(reception.length);

    const reminderNumbers = [];
    notAcknowledgedData.forEach((invite) => reminderNumbers.push(invite.phone));

    // console.log(reminderNumbers);
    const sortedData = [...acknowledgedData, ...notAcknowledgedData];

    setData(sortedData);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // console.log(data);
      let peopleTotal = 0;
      data.forEach((item) => (peopleTotal += item.guests.length));
      // console.log("People Total: ", peopleTotal);
    }
  }, [data]);

  // const handleFix = async (invite) => {
  //   const result = await setGuestData(invite.phone, { guests: invite.guests });
  //   if (result === 200) {
  //     alert("FIXED");
  //   } else {
  //     alert("NOT FIXED");
  //   }
  // };

  // const handleDelete = async (invite) => {
  //   const result = await deleteGuestData(invite.phone);
  //   if (result === 200) {
  //     alert("FIXED");
  //   } else {
  //     alert("NOT FIXED");
  //   }
  // };

  return (
    <>
      <Header>
        <ResponseArea>
          <Names>Guest</Names>
          <div>
            <ResponseArea>
              <Going title>Haldi</Going>
              <Going title>Sangeet</Going>
              <Going title>Shantek</Going>
              <Going title>Ceremony</Going>
              <Going title>Reception</Going>
            </ResponseArea>
            <ResponseArea>
              <Going title>{haldiYes}</Going>
              <Going title>{sangeetYes}</Going>
              <Going title>{shantekYes}</Going>
              <Going title>{ceremonyYes}</Going>
              <Going title>{receptionYes}</Going>
            </ResponseArea>
          </div>
        </ResponseArea>
        <Names>
          REPLIED: {ackLength}
          <sup>invites</sup> | {ackPeople || 0}
          <sup>guests</sup>
        </Names>
        <Names>
          NO REPLY: {noAckLength}
          <sup>invites</sup> | {notAckPeople || 0}
          <sup>guests</sup>
        </Names>
      </Header>
      <Wrapper>
        <hr />
        {data &&
          data.map((reservation, index) => (
            <User index={index}>
              <Phone>{reservation.phone}</Phone>
              {reservation.guests.map((guest) => (
                <ResponseArea>
                  <Names>{guest.name}</Names>
                  <ResponseArea>
                    {guest.events.map((event) => (
                      <Going invited={event.invited}>{event.going}</Going>
                    ))}
                  </ResponseArea>
                </ResponseArea>
              ))}
              {/* <Button onClick={() => handleFix(reservation)}>
                    Fix RSVP
                  </Button> */}
              {/* <Button onClick={() => handleDelete(reservation)}>DELETE</Button> */}
            </User>
          ))}
        <p>{goingPeople.toString()}</p>
      </Wrapper>
    </>
  );
};

export default ListAllPage;
