import { app } from "../firebase-config";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { stripNonDigits } from "../utils/stripNonDigits";

export const getGuestData = async (phoneNum) => {
  const strippedNum = `${stripNonDigits(phoneNum)}`;
  const db = getFirestore(app);

  const docRef = doc(db, "guestList", strippedNum);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    const docRef = doc(db, "guestList", `+${strippedNum}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  }
};
