import spinner from "../images/ui/spinner.gif";
import styled from "styled-components";

const Image = styled.img`
  height: 20px;
  width: 20px;
`;

const Spinner = () => <Image src={spinner} />;

export default Spinner;
