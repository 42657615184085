import React from "react";
import styled from "styled-components";
import {
  colorSecondary,
  colorText,
  fontMain,
  fontTitle,
  textSize,
  textSizeM,
} from "../theme";
import img1 from "../images/ourstory/1.png";
import img2 from "../images/ourstory/2.png";

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const Title = styled.h2`
  font-family: ${fontTitle};
  font-size: ${textSizeM};
  font-weight: 200;
  margin-bottom: 16px;
`;

const Details = styled.p`
  font-family: ${fontMain};
  font-size: ${textSize};
  font-weight: 200;
  color: ${colorText};
  width: 90%;
  max-width: 700px;
  text-align: center;
`;

const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ImgDetails = styled(Details)`
  width: max-content;
`;

const ImgContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const Img = styled.img`
  width: 40%;
`;

const OurStoryPage = () => {
  return (
    <Wrapper>
      <Title>Our Story</Title>
      <Details>
        <span style={{ whiteSpace: "pre-line" }}>
          We first met during our first year of university in 2011.
          <br />
          <br />
          While Rutu attended the University of Waterloo, Chance attended
          Wilfred Laurier University and was roommates with Rutu's high school
          friend. We would occasionally hang out in a group, but were not
          looking to date anyone at the time. Chance moved back home to
          Peterborough to attend Trent University and we eventually lost
          contact.
          <br />
          <br />
          Fast forward to 2017, and our mutual friend was getting married in
          Houston, Texas. We were the last of our friends to book flights, so we
          got in touch again and have been inseparable ever since!
          <br />
          <br />
          For the first four years of our relationship, we did long-distance
          between Mississauga and Peterborough. Eventually in 2021, Chance moved
          into Rutu's home with her family, making him the 7th member of the
          Desai household.
          <br />
          <br />
          A few of our favourite memories together: trying new restaurants and
          recipes together, Croc walks, travelling to many places such as
          Montreal (specifically Mont Tremblant where we got engaged!), British
          Columbia and Mexico, and binge watching movie series (we've watched
          Star Wars, Harry Potter, Jurassic Park, and all of the Marvel movies
          and shows...in story order)!
          <br />
          <br />
          We can't wait to celebrate this Chance to grow our Roots and begin
          this next chapter in our lives with all of you!
        </span>
      </Details>
      <ImgWrap>
        <ImgContent>
          <Img src={img1} loading="lazy" />
          <ImgDetails>Our first photo together</ImgDetails>
        </ImgContent>
        <ImgContent>
          <Img src={img2} loading="lazy" />
          <ImgDetails>Our engagement</ImgDetails>
        </ImgContent>
      </ImgWrap>
    </Wrapper>
  );
};

export default OurStoryPage;
