import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { generateGuestList } from "../../data/guestcsv";
import { setGuestData } from "../../data/setGuestData";
import { colorPrimary, fontMain, textSizeM, textSizeS } from "../../theme";

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: scroll;
`;

const User = styled.div`
  width: 100vw;
  max-width: 600px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-self: center;
  ${({ index }) =>
    index % 2 &&
    `
    background: rgba(0,0,0,0.1);
  `}
`;

const Phone = styled.text`
  font-size: ${textSizeS};
  font-family: ${fontMain};
`;

const Names = styled.text`
  font-size: ${textSizeM};
  font-family: ${fontMain};
`;

const ResponseArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Going = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 75px;
  text-align: center;
  background: blue;
  background: ${({ invited }) => invited === true && "green"};
  background: ${({ invited }) => invited === false && "red"};
`;

const Button = styled.div`
  padding: 8px;
  min-width: 150px;
  color: white;
  background: ${colorPrimary};
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 32px;
`;

const GuestBuilder = () => {
  const [data, setData] = useState([]);
  const [currentInvite, setCurrentInvite] = useState(0);

  useEffect(() => {
    const createdData = generateGuestList();
    setData(createdData);
  }, []);

  const handleSubmit = async () => {
    const result = await setGuestData(data[currentInvite].number, {
      guests: data[currentInvite].guests,
    });
    if (result === 200) {
      alert("success");
    } else {
      // console.log(result);
      alert("failed");
    }
  };

  return (
    <>
      <User>
        <ResponseArea>
          <Names>Guest</Names>
          <ResponseArea>
            <Going>Haldi</Going>
            <Going>Sangeet</Going>
            <Going>Shantek</Going>
            <Going>Ceremony</Going>
            <Going>Reception</Going>
          </ResponseArea>
        </ResponseArea>
      </User>
      <Wrapper>
        <hr />
        {/* ALL DATA */}
        {/* {data &&
          data.map((reservation, index) => (
            <User index={index}>
              <Phone>{reservation.phone}</Phone>
              {reservation.guests.map((guest) => (
                <ResponseArea>
                  <Names>{guest.name}</Names>
                  <ResponseArea>
                    {guest.events.map((event) => (
                      <Going invited={event.invited === "Y"}>
                        {event.going}
                      </Going>
                    ))}
                  </ResponseArea>
                </ResponseArea>
              ))}
            </User>
          ))} */}
        {data && (
          <User>
            <Phone>{data[currentInvite]?.number}</Phone>
            {data[currentInvite]?.guests.map((guest) => (
              <ResponseArea>
                <Names>{guest.name}</Names>
                <ResponseArea>
                  {guest.events.map((event) => (
                    <Going invited={event.invited === true}>
                      {event.going}
                    </Going>
                  ))}
                </ResponseArea>
              </ResponseArea>
            ))}
          </User>
        )}
        <Button onClick={() => handleSubmit()}>SUBMIT</Button>
        <Button onClick={() => console.log(data[currentInvite])}>
          LOG INVITE
        </Button>
        <Button onClick={() => setCurrentInvite(currentInvite + 1)}>
          NEXT
        </Button>
      </Wrapper>
    </>
  );
};

export default GuestBuilder;
