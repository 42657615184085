export const eventDetails = [
  {
    name: "Ganesh Pooja & Haldi",
    date: "Sept 1st, 2022",
    time: "9am | Lunch to follow",
    location: `Desai Residence Backyard | 27 Amity Rd. Mississauga, ON, L5M 1P1`,
    parking: "Street parking",
    dressCode: "Indian or Western Casual",
    description: `We cordially invite you to join us in the Ganesh Pooja & Haldi to mark the beginning of the union of Rutu and Chance.

    Ganesh is regarded as the God that removes all kinds of badhas or obstacles. Therefore, before any wedding ritual can begin, the family worships Lord Ganesh, praying for a smooth and obstacle-free wedding.

    Haldi, or turmeric, is well known for its health and beauty benefits, but it also represents purity, peace, prosperity and a happy beginning. A special paste, consisting of a mixture of haldi, sandalwood powder and rose water, is applied to the face, neck, hands, and feet of the bride and groom by their close family and friends.
    `,
  },
  {
    name: "Mehendi & Sangeet",
    date: "Sept 1st, 2022",
    time: "6pm ",
    location:
      "Desai Residence Backyard | 27 Amity Rd. Mississauga, ON, L5M 1P1",
    parking: "Street Parking",
    dressCode: "Indian or Western Cocktail",
    description: `Please join us for an evening of nachna, gana, and khana at the pre-wedding celebration for Rutu and Chance.

    Sangeet, which means “musical night”, is an event held a few days before the wedding where both the bride and groom's family and friends come together to dance, sing, eat, and put on mehendi (henna) in a vibrant, upbeat environment.
    `,
  },
  {
    name: "Grah Shantek",
    date: "Sept 2nd, 2022",
    time: "11am Grah Shantek | Lunch to follow",
    location:
      "Hindu Heritage Centre | 6300 Mississauga Rd. Mississauga, ON, L5N 1A7",
    parking: "Parking Onsite",
    dressCode: "Indian Formal",
    description: `The Desai family cordially invites you to the Grah Shantek ceremony for Rutu.`,
  },
  {
    name: "Wedding Ceremony",
    date: "Sept 3rd, 2022",
    time: "10am Bharaat arrival & breakfast | 11am Ceremony | Lunch to follow",
    location:
      "Desai Residence Backyard | 27 Amity Rd. Mississauga, ON, L5M 1P1",
    parking:
      "Brides Side Street Parking (Not Amity Road) | Grooms Side Parking Offsite",
    dressCode:
      "Indian or Western Formal | Shades of pink, green, and/or gold | No red",
    description: `Vipul & Sujata Desai request the pleasure of your company at the wedding celebration of their daughter Rutu to Chance, son of Greg Squires and Trina VanBelleghem.`,
  },
  {
    name: "Reception",
    date: "Sept 4th, 2022",
    time: "6pm Cocktails | 7pm Reception",
    location: `Queen's Manor Event Centre | 2 Auction Lane. Brampton, ON, L6T 0C4`,
    parking: "Parking Onsite",
    dressCode: "Indian or Western Formal (Men-Black tie optional)",
    description: `Rutu & Chance request the honour of your presence for a night of cocktails, dinner and dance to celebrate their marriage.`,
  },
];
