import React, { useContext } from "react";
import AddPage from "./pages/admin/AddPage";
import ListAllPage from "./pages/admin/ListAllPage";
import GuestBuilder from "./pages/admin/GuestBuilder";
import styled from "styled-components";
import { AuthContext } from "./context";
import LoginPage from "./pages/LoginPage";
import UserPages from "./pages/UserPages";
import { colorPrimary } from "./theme";
import left from "./images/background/left.png";
import right from "./images/background/right.png";
import { GuestProvider } from "./context/GuestContext";
import Itinerary from "./pages/Itinerary";
import Recorder from "./pages/Recorder";

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: ${colorPrimary};
  width: 100vw;
  overflow: hidden;
`;

const PageWrap = styled.div`
  z-index: 1;
`;

const LeftImg = styled.img`
  height: 75vh;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const RightImg = styled.img`
  height: 40vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
`;

function App() {
  const { user } = useContext(AuthContext);
  const adminMode = false;

  const query = window.location.pathname;

  if (adminMode) {
    return (
      <Wrapper>
        {/* <AddPage /> */}
        <ListAllPage />
        {/* <GuestBuilder /> */}
      </Wrapper>
    );
  }

  if (query.includes("itinerary")) {
    return (
      <Wrapper>
        <PageWrap>
          <Itinerary />
        </PageWrap>
      </Wrapper>
    );
  }

  if (query.includes("recorder")) {
    return (
      <Wrapper>
        <PageWrap>
          <Recorder />
        </PageWrap>
      </Wrapper>
    );
  }

  if (user) {
    return (
      <Wrapper>
        <LeftImg src={left} />
        <RightImg src={right} />
        <PageWrap>
          <GuestProvider>
            <UserPages />
          </GuestProvider>
        </PageWrap>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LeftImg src={left} />
      <RightImg src={right} />
      <PageWrap>
        <LoginPage />
      </PageWrap>
    </Wrapper>
  );
}

export default App;
