import React, { useContext } from "react";
import styled from "styled-components";
import { GuestContext } from "../context/GuestContext";
import { colorSecondary, fontMain, textSizeM } from "../theme";
import HotelDetails from "../components/HotelDetails";
import { mississaugaHotelData, bramptonHotelData } from "../data/hotelData";

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const Title = styled.h2`
  font-family: ${fontMain};
  font-size: ${textSizeM};
  font-weight: 200;
  margin-bottom: 16px;
`;

const HR = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const AccommodationPage = () => {
  const { guestData } = useContext(GuestContext);
  const hasHomeEvents = guestData?.guests?.some(
    (guest) => guest?.events?.length > 1
  );

  return (
    <Wrapper>
      {hasHomeEvents && (
        <>
          <Title>Mississauga Hotels</Title>
          {mississaugaHotelData.map((hotel) => (
            <HotelDetails hotel={hotel} />
          ))}
        </>
      )}
      <HR />
      <Title>Brampton Hotels</Title>
      {bramptonHotelData.map((hotel) => (
        <HotelDetails hotel={hotel} />
      ))}
    </Wrapper>
  );
};

export default AccommodationPage;
