import React from "react";
import styled from "styled-components";
import PhoneAuthForm from "../components/PhoneAuthForm";
import logo from "../images/login-logo.png";
import { colorSecondary, fontMain, textSizeM } from "../theme";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 350px;
  max-width: 75%;
  margin-top: 5%;
`;

const Date = styled.p`
  font-family: ${fontMain};
  font-size: ${textSizeM};
  color: ${colorSecondary};
  margin: 0;
  padding: 0;
`;

const FlexArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LoginPage = () => (
  <Wrapper>
    <Image src={logo} />
    <Date>
      SEPTEMBER 1<sup>st</sup> - 4<sup>th</sup> 2022
    </Date>
    <FlexArea>
      <PhoneAuthForm />
    </FlexArea>
  </Wrapper>
);

export default LoginPage;
