export const colorPrimary = "#798979";
export const colorSecondary = "#EBEBDF";
export const colorText = "#4E4E4E";
export const colorBlack = "#000";
export const colorWhite = "#FFF";

export const fontXL = "95px";
export const fontL = "41px";
export const fontM = "38px";
export const fontS = "32px";
export const fontXS = "24px";
export const fontXXS = "20px";
export const fontXXXS = "16px";

export const textSizeS = "12px";
export const textSizeBad = "14px";
export const textSize = "16px";
export const textSizeM = "20px";
export const textSizeL = "24px";

export const fontTitle = "BritishCastilla";
export const fontMain = "Harmony";
