export const icons = {
  user: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
    >
      <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418C20.452 4.766 16.983 0 12 0 6.918 0 3.536 4.949 8.268 13.678c1.597 2.945-1.725 3.641-5.09 4.418C.105 18.806-.01 20.332 0 23l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
    </svg>
  ),
  hamburger: (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <path d="M24 6H0V2h24v4zm0 4H0v4h24v-4zm0 8H0v4h24v-4z" />
    </svg>
  ),
  calendar: (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <path d="M24 2v22H0V2h3v1c0 1.103.897 2 2 2s2-.897 2-2V2h10v1c0 1.103.897 2 2 2s2-.897 2-2V2h3zm-2 6H2v14h20V8zm-2-7a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V1zM6 3a1 1 0 1 1-2 0V1a1 1 0 1 1 2 0v2zm6.687 13.482c0-.802-.418-1.429-1.109-1.695.528-.264.836-.807.836-1.503 0-1.346-1.312-2.149-2.581-2.149-1.477 0-2.591.925-2.659 2.763h1.645c-.014-.761.271-1.315 1.025-1.315.449 0 .933.272.933.869 0 .754-.816.862-1.567.797v1.28c1.067 0 1.704.067 1.704.985 0 .724-.548 1.048-1.091 1.048-.822 0-1.159-.614-1.188-1.452H7.001C6.969 18.002 8.115 19 9.843 19c1.543 0 2.844-.943 2.844-2.518zM17 19v-7.718h-1.392c-.173 1.154-.995 1.491-2.171 1.459v1.346h1.852V19H17z" />
    </svg>
  ),
  time: (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm1 12V6h-2v8h7v-2h-5z" />
    </svg>
  ),
  location: (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <path d="M12 0C7.802 0 4 3.403 4 7.602 4 11.8 7.469 16.812 12 24c4.531-7.188 8-12.2 8-16.398C20 3.403 16.199 0 12 0zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
    </svg>
  ),
  clothing: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M19 24H5V8L3 9.084 0 5.472 6 0h12l6 5.505-3 3.647L19 8v16zM8 1h8c-.365 1.846-1.792 3.5-4 3.5S8.365 2.792 8 1z" />
    </svg>
  ),
  leftArrow: (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.012 2c5.518 0 9.997 4.48 9.997 9.998 0 5.517-4.479 9.997-9.997 9.997s-9.998-4.48-9.998-9.997C2.014 6.48 6.494 2 12.012 2zm-1.523 6.21-3.255 3.259a.748.748 0 0 0 0 1.061l3.254 3.258a.74.74 0 0 0 .526.217.753.753 0 0 0 .531-.221.748.748 0 0 0 .003-1.057L9.571 12.75h6.693a.75.75 0 0 0 0-1.5H9.571l1.978-1.979a.754.754 0 0 0-.536-1.276c-.19 0-.38.071-.524.215z"
        fillRule="nonzero"
      />
    </svg>
  ),
  rightArrow: (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.007 2c-5.518 0-9.998 4.48-9.998 9.998 0 5.517 4.48 9.997 9.998 9.997s9.998-4.48 9.998-9.997C22.005 6.48 17.525 2 12.007 2zm1.523 6.21 3.255 3.259a.748.748 0 0 1 0 1.061l-3.254 3.258a.74.74 0 0 1-.526.217.753.753 0 0 1-.531-.221.748.748 0 0 1-.003-1.057l1.977-1.977H7.755a.75.75 0 0 1 0-1.5h6.693L12.47 9.271a.754.754 0 0 1 .536-1.276c.19 0 .38.071.524.215z"
        fillRule="nonzero"
      />
    </svg>
  ),
  phone: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
    >
      <path d="m20 22.621-3.521-6.795a618.8 618.8 0 0 1-2.064 1.011c-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026L8.396 0 6.29 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
    </svg>
  ),
  parking: (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <path d="M19.688 4.176c0 .82-.603 1.109-1.423 1.109h-.509V3.107h.704c.775 0 1.228.283 1.228 1.069zM24 2.292v6.417A2.292 2.292 0 0 1 21.708 11h-6.417A2.292 2.292 0 0 1 13 8.708V2.292A2.292 2.292 0 0 1 15.292 0h6.417A2.292 2.292 0 0 1 24 2.292zm-2.75 1.829c0-.759-.231-1.33-.691-1.713-.461-.383-1.134-.574-2.019-.574h-2.331v7.333h1.547V6.558h.664c.905 0 1.603-.209 2.094-.627.49-.418.736-1.021.736-1.81zM2.702 10H.5a.5.5 0 0 0-.5.5v.511c0 .793.926.989 1.616.989l1.086-2zm19.08 2.993-.074.007h-6.417c-5.448 0-8.12-.359-10.233-1.027 0 0 .74-1.826 1.631-3.142.202-.298.515-.502.869-.566A22.362 22.362 0 0 1 11 7.922V6.014c-1.432.03-2.601.127-3.851.351-1.016.182-1.45.507-2.278 1.889-1.078 1.799-2.101 3.715-2.929 5.252-.639 1.186-.946 2.252-.946 3.666 0 1.37.397 2.533 1.005 3.981V23a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1v-1h13v1a1 1 0 0 0 1 1H21a1 1 0 0 0 1-1v-1.847c.608-1.448 1.005-2.611 1.005-3.981 0-1.414-.307-2.48-.946-3.666l-.277-.513zM4.5 18a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 4.5 18zm11 0h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zm4 0a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 19.5 18z" />
    </svg>
  ),
};
