import React, { useState } from "react";
import styled from "styled-components";
import {
  colorPrimary,
  colorSecondary,
  colorText,
  fontMain,
  textSize,
} from "../../theme";
import { authentication } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import InputMask from "react-input-mask";
import { stripNonDigits } from "../../utils/stripNonDigits";
import { getGuestData } from "../../data/getGuestData";
import { countryCodes } from "../../data/countryCodes";
import Spinner from "../Spinner";

const FormWrap = styled.div`
  background: ${colorSecondary};
  padding: 24px 16px;
  border-radius: 5px;
  width: 500px;
  min-height: 160px;
  @media only screen and (max-width: 600px) {
    width: 95vw;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: transparent;
`;

const Subtitle = styled.h1`
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
  font-size: ${textSize};
  font-family: ${fontMain};
  font-weight: 200;
  color: ${({ error }) => (error ? "#964d4d" : colorText)};
  text-align: center;
`;

const InputWrap = styled.div`
  display: flex;
`;

const Masked = styled(InputMask)`
  height: 44px;
  border: none;
  font-size: ${textSize};
  font-family: "Lato", sans-serif;
  text-align: center;
  border-radius: 5px;
  flex: 1;
  ${({ step }) =>
    step === 1 &&
    `
    border-radius: 0 5px 5px 0;
  `}
  ${({ error }) =>
    error
      ? `
    background: #ffebeb;
    outline: 2px solid #964d4d;
    color: #964d4d;
  `
      : []}
  :focus {
    outline: 2px solid ${colorPrimary};
  }
`;

const CountrySelect = styled.select`
  height: 44px;
  border-radius: 5px 0 0 5px;
  border: none;
  background: ${colorPrimary};
  color: white;
  padding-left: 5px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const GetCodeBtn = styled.button`
  height: 44px;
  border-radius: 5px;
  width: 100%;
  border: none;
  background: ${colorPrimary};
  color: white;
  margin-top: 8px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    background: rgba(133, 156, 131, 0.5);
  `}
`;

const PhoneAuthForm = () => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNum, setPhoneNum] = useState("");
  const [timedOut, setTimedOut] = useState(false);
  const [otp, setOTP] = useState("");

  const changeCountryCode = (code) => {
    setCountryCode(code);
  };

  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCapthca solver
        },
      },
      authentication
    );
  };

  const requestOTP = async (e) => {
    e.preventDefault();
    const strippedNum = stripNonDigits(phoneNum);
    setLoading(true);
    generateRecaptchaVerifier();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      authentication,
      `${countryCode}${strippedNum}`,
      appVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setStep(2);
        setLoading(false);
        setTimedOut(true);
      })
      .catch((error) => {
        // err sms not sent
        setLoading(false);
      });
  };

  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);

    let strippedOTP = stripNonDigits(otp);

    if (strippedOTP.length === 6) {
      setLoading(true);
      // verify otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(strippedOTP)
        .then(async (result) => {
          // user signed in successfully
          const user = result.user;
          // console.log("user: ", user);
          const data = await getGuestData(stripNonDigits(phoneNum));
          if (data) {
            // setData(data);
            // console.log(data);
            setLoading(false);
            setStep(3);
          } else {
            setLoading(false);
            setError(
              `Oops! We can't find that phone number! Please use the phone number that received the invite!`
            );
          }
        })
        .catch((error) => {
          setError(`Something went wrong, please check your code is correct.`);
          setLoading(false);
          // user couldn't sign in (bad code?)
        });
    }
  };

  React.useEffect(() => {
    if (timedOut) {
      const timer = setTimeout(() => {
        setTimedOut(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [timedOut]);

  return (
    <FormWrap>
      {step === 1 && (
        <Form onSubmit={requestOTP}>
          {error ? (
            <Subtitle error>{error}</Subtitle>
          ) : (
            <Subtitle>
              Please enter the phone number we sent your invite to
            </Subtitle>
          )}
          <InputWrap>
            <CountrySelect
              onChange={(e) => changeCountryCode(e.target.value)}
              disabled={loading}
            >
              {countryCodes.map(({ flag, code }) => (
                <option value={code} key={flag}>
                  {flag} {code}
                </option>
              ))}
            </CountrySelect>
            <Masked
              step={step}
              mask={"(999)-999-9999"}
              alwaysShowMask
              value={phoneNum}
              onChange={(e) => setPhoneNum(e.target.value)}
              autoComplete="tel"
              type="tel"
              name="cell number"
              disabled={loading}
              onFocus={() => setError("")}
              error={error}
            />
          </InputWrap>
          <GetCodeBtn id="sign-in-button" type="submit" disabled={loading}>
            {loading ? <Spinner /> : "Get Code"}
          </GetCodeBtn>
        </Form>
      )}

      {step === 2 && (
        <Form>
          <Subtitle>
            {error ? error : `Enter the code sent to ${phoneNum}`}
          </Subtitle>
          <InputWrap>
            <Masked
              mask={"9  9  9  9  9  9"}
              name="otp code"
              type="text"
              placeholder="Verification Code"
              value={otp}
              onChange={verifyOTP}
              disabled={loading}
              onFocus={() => setError("")}
              error={error}
            />
          </InputWrap>
          <GetCodeBtn disabled={loading || timedOut} type="submit">
            {loading ? <Spinner /> : "Send Again"}
          </GetCodeBtn>
        </Form>
      )}
      <div id="recaptcha-container" style={{ position: "absolute" }}></div>
    </FormWrap>
  );
};

export default PhoneAuthForm;
