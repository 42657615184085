import React from "react";
import styled from "styled-components";
import { colorSecondary, fontMain, fontXXXS } from "../../theme";

const Wrapper = styled.div`
  min-width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const NavItem = styled.a`
  text-decoration: none;
  color: ${colorSecondary};
  font-size: ${fontXXXS};
  font-family: ${fontMain};
  padding: 2px;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
  ${({ active }) => active && `border-bottom: 2px solid ${colorSecondary};`}
`;

const Navbar = ({ currentPage, setCurrentPage }) => (
  <Wrapper>
    <NavItem
      active={currentPage === "home"}
      onClick={() => setCurrentPage("home")}
    >
      Home
    </NavItem>
    <NavItem
      active={currentPage === "events"}
      onClick={() => setCurrentPage("events")}
    >
      Events
    </NavItem>
    {/* <NavItem
      active={currentPage === "accommodations"}
      onClick={() => setCurrentPage("accommodations")}
    >
      Accommodations
    </NavItem> */}
    <NavItem
      active={currentPage === "photos"}
      onClick={() => setCurrentPage("photos")}
    >
      Photos
    </NavItem>
    <NavItem
      active={currentPage === "ourStory"}
      onClick={() => setCurrentPage("ourStory")}
    >
      Our Story
    </NavItem>
    <NavItem
      active={currentPage === "rsvp"}
      onClick={() => setCurrentPage("rsvp")}
    >
      RSVP
    </NavItem>
  </Wrapper>
);

export default Navbar;
