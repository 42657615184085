import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GuestContext } from "../context/GuestContext";
import { eventDetails } from "../data/eventDetails";
import {
  colorPrimary,
  colorSecondary,
  colorText,
  fontMain,
  fontTitle,
  textSize,
  textSizeBad,
  textSizeL,
  textSizeM,
  textSizeS,
} from "../theme";
import ganesh from "../images/ganesh.png";
import { icons } from "../icons";

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  margin-bottom: 64px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    max-width: 95%;
  }
`;

const LeftMenu = styled.div`
  max-width: 250px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 0 0 5px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileMenuToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  width: 200px;
  border-radius: 5px;
  margin-top: 32px;
  background: ${colorPrimary};
  color: ${colorSecondary};
  align-self: center;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

const MobileMenu = styled(LeftMenu)`
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
    max-width: 100%;
    align-items: flex-start;
    margin-top: 8px;
  }
`;

const Img = styled.img`
  width: 64px;
  margin-bottom: 16px;
`;

const GaneshText = styled.p`
  font-family: ${fontMain};
  font-size: ${textSizeS};
  color: ${colorText};
  font-weight: 200;
  margin-bottom: 16px;
`;

const SmallImg = styled.img`
  width: 32px;
`;

const RightMenu = styled.div`
  flex: 1;
  background: white;
  margin: 64px 64px;
  text-align: center;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin: 16px 16px;
  }
`;

const EventItem = styled.div`
  cursor: pointer;
  width: 100%;
  border-left: 8px solid
    ${({ active }) => (active ? colorSecondary : "transparent")};
  min-height: 50px;
  padding-left: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  :hover {
    background: #eee;
    transition: 200ms;
  }
`;

const EventItemName = styled.h1`
  font-family: ${fontMain};
  font-size: ${textSizeM};
  color: ${colorText};
  font-weight: 200;
  ${({ active }) => !active && "filter: opacity(50%);"}
`;

const EventItemTime = styled.h2`
  font-family: ${fontMain};
  font-size: ${textSize};
  color: ${colorText};
  font-weight: 200;
  ${({ active }) => !active && "filter: opacity(50%);"}
`;

const EventTitle = styled.h1`
  font-family: ${fontTitle};
  font-size: ${textSizeL};
  color: ${colorText};
  font-weight: 200;
`;

const EventDescription = styled.p`
  font-family: ${fontMain};
  font-size: ${textSizeBad};
  color: ${colorText};
  font-weight: 200;
  max-width: 650px;
`;

const RsvpButton = styled.div`
  cursor: pointer;
  height: 44px;
  min-width: 200px;
  background: ${colorPrimary};
  color: ${colorSecondary};
  font-family: ${fontMain};
  font-size: ${textSize};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  :hover {
    filter: opacity(75%);
    transition: 200ms;
  }
`;

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`;

const NewDetailBox = styled.div`
  border-radius: 5px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
  text-align: left;
  ${({ middle, end }) => (middle || end) && "border-top: none;"};
`;

const DetailText = styled.h2`
  all: unset;
  font-family: ${fontMain};
  font-size: ${textSizeBad};
  color: ${colorText};
`;

const Icon = styled.div`
  height: 100%;
  padding: 4px;
  margin-right: 4px;
  svg {
    fill: ${colorPrimary};
    transform: scale(0.6);
  }
`;

const EventPage = ({ setCurrentPage }) => {
  const data = eventDetails;
  const { guestData } = useContext(GuestContext);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [active, setActive] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!guestData) {
      return;
    }
    const events = [];
    guestData?.guests.map((guest) =>
      guest.events.map((event) => {
        if (event.invited === true) {
          events.push(event.eventName);
        }
      })
    );
    const invitedEvents = [...new Set(events)];

    const filteredResult = [];
    data.forEach(
      (event) =>
        invitedEvents.includes(event.name) && filteredResult.push(event)
    );

    setFilteredEvents(filteredResult);
    setActive(filteredResult[0].name);
  }, [guestData, data]);

  const handleEventClick = (name) => {
    window.scrollTo(0, 0);
    setActive(name);
    mobileMenuOpen && setMobileMenuOpen(false);
  };

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Wrapper>
      <LeftMenu>
        <div>
          {filteredEvents.map(({ name, date, time }) => (
            <EventItem
              active={active === name}
              onClick={() => handleEventClick(name)}
            >
              <EventItemName active={active === name}>{name}</EventItemName>
              <EventItemTime active={active === name}>{date}</EventItemTime>
              <EventItemTime active={active === name}>
                {time.substring(0, time.indexOf(" "))}
              </EventItemTime>
            </EventItem>
          ))}
        </div>
        <Img src={ganesh} />
      </LeftMenu>
      {filteredEvents?.length > 1 && (
        <>
          <MobileMenuToggle onClick={() => toggleMenu()}>
            {mobileMenuOpen ? "Hide Events" : "Show Events"}
          </MobileMenuToggle>
          {mobileMenuOpen && (
            <MobileMenu>
              <div>
                {filteredEvents.map(({ name, date, time }) => (
                  <EventItem
                    active={active === name}
                    onClick={() => handleEventClick(name)}
                  >
                    <EventItemName active={active === name}>
                      {name}
                    </EventItemName>
                    <EventItemTime active={active === name}>
                      {date}
                    </EventItemTime>
                    <EventItemTime active={active === name}>
                      {time.substring(0, time.indexOf(" "))}
                    </EventItemTime>
                  </EventItem>
                ))}
              </div>
            </MobileMenu>
          )}
        </>
      )}
      <RightMenu>
        {filteredEvents.map(
          ({
            name,
            date,
            time,
            location,
            dressCode,
            description,
            parking,
            parkingAddress,
          }) =>
            name === active && (
              <>
                <SmallImg src={ganesh} />
                <GaneshText>Om Ganeshay Namah</GaneshText>
                <EventTitle>{name}</EventTitle>
                <br />
                <NewDetailBox>
                  <DetailItem>
                    <Icon>{icons.calendar}</Icon>
                    <DetailText active>{date}</DetailText>
                  </DetailItem>
                  <DetailItem middle>
                    <Icon>{icons.time}</Icon>
                    <DetailText active>{time}</DetailText>
                  </DetailItem>
                  <DetailItem middle>
                    <Icon>{icons.location}</Icon>
                    <DetailText active>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`http://maps.google.com/?q=${
                          location.split("|")[location.split("|").length - 1]
                        }`}
                      >
                        {location}
                      </Link>
                    </DetailText>
                  </DetailItem>
                  <DetailItem middle>
                    <Icon>{icons.parking}</Icon>
                    <div>
                      <DetailText active>{parking}</DetailText>
                      {parkingAddress && (
                        <>
                          <br />
                          <DetailText active>
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`http://maps.google.com/?q=${parkingAddress}`}
                            >
                              {parkingAddress}
                            </Link>
                          </DetailText>
                        </>
                      )}
                    </div>
                  </DetailItem>
                  <DetailItem end>
                    <Icon>{icons.clothing}</Icon>
                    <DetailText active>{dressCode}</DetailText>
                  </DetailItem>
                </NewDetailBox>
                <br />
                <span style={{ whiteSpace: "pre-line" }}>
                  <EventDescription>{description}</EventDescription>
                </span>
              </>
            )
        )}
        {/* <RsvpButton onClick={() => setCurrentPage("rsvp")}>RSVP</RsvpButton> */}
      </RightMenu>
    </Wrapper>
  );
};

export default EventPage;
