import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyC8RQlV8nKS2yJhFIQyb07q6veHR6A3bC0",
  authDomain: "chance-rutu-rsvp.firebaseapp.com",
  projectId: "chance-rutu-rsvp",
  storageBucket: "chance-rutu-rsvp.appspot.com",
  messagingSenderId: "983717955101",
  appId: "1:983717955101:web:4fe88ce3d17cd9ec9928f4",
  measurementId: "G-8TQHQPS9H4"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);