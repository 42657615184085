import React from "react";
import { getAuth } from "firebase/auth";
import styled from "styled-components";
import { icons } from "../../icons";
import { colorPrimary, colorSecondary, fontMain, fontXXXS } from "../../theme";
import { GuestContext } from "../../context/GuestContext";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  background: ${colorSecondary};
  padding: 16px;
  padding-bottom: 8px;
  margin-right: 8px;
  border-radius: 0 0 5px 5px;
  svg {
    fill: ${colorPrimary};
    margin-left: 8px;
    margin-right: 2px;
    width: 14px;
    height: 14px;
  }
`;

const NumGuests = styled.p`
  font-family: ${fontMain};
  color: ${colorPrimary};
  font-size: ${fontXXXS};
  padding: 0;
  margin: 0;
  line-height: 16px;
  font-weight: bold;
`;

const LogoutText = styled.a`
  font-family: ${fontMain};
  color: ${colorPrimary};
  font-size: ${fontXXXS};
  text-decoration: underline;
  margin-left: 12px;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;

const Logout = () => {
  const { guestData } = React.useContext(GuestContext);

  const handleLogout = () => {
    const auth = getAuth();

    if (auth.currentUser) {
      auth.signOut();
    }
  };

  return (
    <Wrapper>
      <NumGuests>Hi, {guestData?.guests[0]?.name}</NumGuests>
      {icons.user}
      <NumGuests>
        <strong>{guestData?.guests.length}</strong>
      </NumGuests>
      <LogoutText onClick={handleLogout}>Logout</LogoutText>
    </Wrapper>
  );
};

export default Logout;
