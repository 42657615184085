import React from "react";
import styled from "styled-components";
import {
  colorPrimary,
  fontMain,
  textSizeS,
  textSize,
  textSizeM,
} from "../../theme";
import { icons } from "../../icons";

const HotelName = styled.h2`
  all: unset;
  font-family: ${fontMain};
  font-size: ${textSizeM};
  font-weight: 600;
  color: black;
`;

const Address = styled.h3`
  all: unset;
  font-family: ${fontMain};
  font-size: ${textSizeS};
  font-weight: 200;
  color: black;
  margin-bottom: 8px;
`;

const Details = styled.h3`
  all: unset;
  font-family: ${fontMain};
  font-size: ${textSize};
  font-weight: 200;
  color: black;
  svg {
    fill: ${colorPrimary};
    width: 16px;
    height: 16px;
  }
`;

const HotelItem = styled.div`
  width: 95%;
  max-width: 600px;
  background: white;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 16px;
  ${({ groupRate }) =>
    groupRate &&
    `
    border: 2px solid ${colorPrimary};
  `}
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const HotelImg = styled.img`
  width: 20%;
  border-radius: 5px;
  margin-right: 16px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 8px;
    width: 50%;
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

const HR = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const Button = styled.div`
  padding: 8px;
  color: white;
  background: ${colorPrimary};
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
`;

const Link = styled.a`
  all: unset;
  cursor: pointer;
`;

const BookingDetails = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    justify-content: space-between;
  }
`;

const HotelDetails = ({ hotel }) => {
  const {
    imgSrc,
    name,
    address,
    groupRate,
    bookingLink,
    phoneNumber,
    phoneDetails,
  } = hotel;
  return (
    <HotelItem groupRate={groupRate}>
      <HotelImg src={imgSrc} />
      <InfoArea>
        <HotelName>{name}</HotelName>
        <Address>
          <Link
            href={`http://maps.google.com/?q=${name} ${address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}
          </Link>
        </Address>
        {groupRate && (
          <>
            <HR />
            <Details>Group Rates Available!</Details>
          </>
        )}
        <HR />
        <BookingDetails>
          <Details>
            <Link href={bookingLink} target="_blank" rel="noopener noreferrer">
              <Button>Book Online</Button>
            </Link>
          </Details>
          <Details>
            {icons.phone} Call:{" "}
            <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
            {phoneDetails}
          </Details>
        </BookingDetails>
      </InfoArea>
    </HotelItem>
  );
};

export default HotelDetails;
