import React from "react";
import styled from "styled-components";
import { colorBlack, colorSecondary, fontMain, fontTitle } from "../theme";
import agnipooja from "../images/itinerary/agnipooja.png";
import akhand from "../images/itinerary/akhand.png";
import ashirwad from "../images/itinerary/ashirwad.png";
import baraat from "../images/itinerary/baraat.png";
import ganeshpooja from "../images/itinerary/ganeshpooja.png";
import gathbandhan from "../images/itinerary/gathbandhan.png";
import hastamilap from "../images/itinerary/hastamilap.png";
import jaimala from "../images/itinerary/jaimala.png";
import mangalphera from "../images/itinerary/mangalphera.png";
import mangalsutra from "../images/itinerary/mangalsutra.png";
import pokhvanu from "../images/itinerary/pokhvanu.png";
import rutuentrance from "../images/itinerary/rutuentrance.png";
import vidaai from "../images/itinerary/vidaai.png";
import feet from "../images/itinerary/feet.png";
import cake from "../images/itinerary/cake.png";
import flower from "../images/itinerary/flower.png";
import breakfast from "../images/itinerary/breakfast.png";
import pictures from "../images/itinerary/pictures.png";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background: white;
  min-height: 100vh;
  width: 90vw;
  border-radius: 5px;
  padding: 20px;
`;

const Title = styled.h1`
  font-family: ${fontTitle};
  color: ${colorBlack};
  text-align: center;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: ${fontMain};
  color: ${colorBlack};
`;

const Description = styled.p`
  font-family: ${fontMain};
  color: ${colorBlack};
  flex-1;
`;

const EventWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  ${({ index }) =>
    index % 2
      ? `
    flex-direction: row;
    justify-content: flex-start;
  `
      : `
  flex-direction: row-reverse;
  justify-content: space-between;
  `}
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const Event = styled.div`
  padding: 0 5px;
  width: 100%;
  ${({ index }) =>
    index % 2
      ? `
    text-align: left;
    `
      : `
      text-align: right;
  `}
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

const Img = styled.img`
  min-width: 200px;
  height: 200px;
`;

const Itinerary = () => {
  const data = [
    {
      title: "Baraat (Chance's Procession)",
      description:
        "Chance, his family, and his friends get their dance on while making their way to the wedding mandap. Rutu's family welcomes Chance's family.",
      img: baraat,
    },
    {
      title: "Pokhvanu/Aragvanu (Welcoming Chance)",
      description:
        "A ritual for Rutu's mom to welcome Chance and his family as well as friends.",
      img: pokhvanu,
    },
    {
      title: "Breakfast",
      description:
        "Chance, his family, and his friends will be offered breakfast, coffee, and tea before the ceremony begins.",
      img: breakfast,
    },
    {
      title: `Ganesh Pooja`,
      description: `The wedding ceremony begins by offering prayer to Lord Ganesh so that
    he may remove all obstacles and bless the couple's new life together.`,
      img: ganeshpooja,
    },
    {
      title: `Antarpat & Kanya Agaman (Rutu's Entrance)`,
      description: `To make sure Chance doesn't catch a sneak peek, an antarpat (cloth) is held up in front of him prior to Rutu's entrance. Rutu is escorted down the aisle by her mother's brothers and seated in the mandap. The antarpat is lowered
    and Chance and Rutu see each other!`,
      img: rutuentrance,
    },
    {
      title: `Jaimala (Exchange of Garlands)`,
      description: `Chance and Rutu exchange floral garlands, signifying the acceptance of one another and to pledge their respect as partners for life.`,
      img: jaimala,
    },
    {
      title: `Hastamilap (Union of the Hands)`,
      description: `Rutu's hand is placed in Chance's.`,
      img: hastamilap,
    },
    {
      title: `Gathbandhan`,
      description: `Chance and Rutu literally tie the knot!`,
      img: gathbandhan,
    },
    {
      title: `Agni Pooja`,
      description: `A sacred fire (Agni) is lit to invite God, the ultimate witness to the marriage.`,
      img: agnipooja,
    },
    {
      title: `Mangal Phera (Circling the Holy Fire)`,
      description: `Chance and Rutu circle the fire four times. Rutu's brothers are called to participate in the ceremony and help to offer jav-tal (wild grain & sesame seeds) to the Agni. The four circles symbolize the four basic human goals of Dharma (religion and ethics), Artha (wealth and prosperity), Karma (love, fertility and family) and Moksha (spiritual liberation and salvation).`,
      img: mangalphera,
    },
    {
      title: `Saptapadi (Seven Steps)`,
      description: `Chance and Rutu take seven steps over sooparis (beetle nuts) representing the vows and promises they are making to each other`,
      img: feet,
    },
    {
      title: `Mangal Sutra & Sindoor Daan`,
      description: `Chance ties a mangal sutra (a sacred necklace made from black beads) around Rutu's neck; this is a symbol of their marriage. Chance then applies sindoor (vermillion powder) on the parting of Rutu's hair as a promise to fulfill her every wish.`,
      img: mangalsutra,
    },
    {
      title: `Akhand Saubhagyavati`,
      description: `Rutu's mother comes and blesses the bride by whispering “Akhand Saubhagyavati”, in the bride's ear, which means “Good luck, prosperity, and a long, happy life.”`,
      img: akhand,
    },
    {
      title: `Ashirwad (Blessings)`,
      description: `Chance and Rutu receive blessings from both sets of parents, the priest and all the guests that have honoured them with their presence.`,
      img: ashirwad,
    },
    {
      title: `Recessional`,
      description: `Chance and Rutu walk down the aisle together, while guests celebrate by tossing dried flower petals over the newlyweds.`,
      img: flower,
    },
    {
      title: `Photos`,
      description: `Guests will be called up in set groups to take a photo with the newlyweds.`,
      img: pictures,
    },
    {
      title: `Lunch and Cake Cutting`,
      description: ``,
      img: cake,
    },
    {
      title: `Vidaai (Farewell to the Bride)`,
      description: `The vidaai is a very touching and emotional farewell to Rutu by her family and friends as she begins her new journey with Chance! Rutu throws a handful of rice behind her shoulders wishing her childhood home happiness and security.`,
      img: vidaai,
    },
  ];

  return (
    <Wrapper>
      <Card>
        <Title>Wedding Day Itinerary</Title>
        {data.map((item, index) => (
          <EventWrap index={index}>
            <Img src={item.img} />
            <Event index={index}>
              <Subtitle>{item.title}</Subtitle>
              <Description>{item.description}</Description>
            </Event>
          </EventWrap>
        ))}
      </Card>
    </Wrapper>
  );
};

export default Itinerary;
