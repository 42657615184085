export const countryCodes = [
  {
    flag: "🇨🇦",
    code: "+1",
  },
  {
    flag: "🇺🇸",
    code: "+1",
  },
  {
    flag: "🇿🇲",
    code: "+260",
  },
  {
    flag: "🇧🇼",
    code: "+267",
  },
  {
    flag: "🇮🇳",
    code: "+91",
  },
  {
    flag: "🇦🇪",
    code: "+971",
  },
];
