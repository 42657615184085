import React from "react";
import styled from "styled-components";
import { colorSecondary, fontMain, textSize } from "../../theme";

const Wrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.h4`
  font-family: ${fontMain};
  font-weight: bold;
  font-size: ${textSize};
  color: ${colorSecondary};
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Footer = () => (
  <Wrapper>
    <Text>Thanks for celebrating with us!</Text>
  </Wrapper>
);

export default Footer;
