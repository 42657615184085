import { getAuth } from "firebase/auth";
import React from "react";
import styled from "styled-components";
import Spinner from "../components/Spinner";
import { GuestContext } from "../context/GuestContext";
import { setGuestData } from "../data/setGuestData";
import {
  colorPrimary,
  colorSecondary,
  fontMain,
  fontTitle,
  textSize,
  textSizeL,
  textSizeM,
} from "../theme";

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 4px;
`;

const Title = styled.h1`
  font-family: ${fontTitle};
  font-size: ${textSizeL};
  font-weight: 200;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-family: ${fontMain};
  font-size: ${textSizeM};
  font-weight: 200;
  text-align: center;
`;

const YesButton = styled.div`
  cursor: pointer;
  height: 44px;
  width: 200px;
  background: ${colorPrimary};
  color: ${colorSecondary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  :hover {
    filter: opacity(75%);
    transition: 200ms;
  }
`;

const GuestArea = styled.div`
  width: 90%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Events = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-count: 2;
`;

const EventItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 45%;
  margin: 5px 15px 5px 15px;
  min-height: 60px;
  padding: 0px 20px 0 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const EmptyEventItem = styled.div`
  flex: 1;
  min-width: 45%;
  margin: 5px 15px 5px 15px;
  padding: 0px 20px 0 20px;
`;

const EventDate = styled(Subtitle)`
  font-size: 12px;
`;

const EventName = styled(Subtitle)`
  margin: 10px 0px;
  font-weight: 200;
  font-size: ${textSize};
  padding: 0px 8px;
`;

const Select = styled.select`
  height: 35px;
  background: #859c83;
  color: white;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  ${({ value }) =>
    value === "Yes"
      ? `
    background: #859c83;
    color: white;
  `
      : []}
  ${({ value }) =>
    value === "No"
      ? `
    background: #964d4d;
    color: white;
  `
      : []}
  ${({ value }) =>
    value === "" || value === "---"
      ? `
    background: #fff;
    color: #333;
  `
      : []}
`;

const RSVPPage = () => {
  const { guestData } = React.useContext(GuestContext);
  const [data, setData] = React.useState(guestData);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const changeSelection = (guest, event, response) => {
    const tempData = data;
    tempData.guests[guest].events[event].going = response;
    setData({ ...tempData });
  };

  const handleAllYes = (guest) => {
    const tempData = data;
    tempData.guests[guest].events.forEach((event) => {
      if (event.invited) {
        event.going = "Yes";
      }
    });
    setData({ ...tempData });
  };

  const handleConfirmRSVP = async () => {
    const auth = getAuth();

    setError(false);
    setLoading(true);
    const result = await setGuestData(auth.currentUser.phoneNumber, data);
    if (result === 200) {
      setError("RSVP received successfully!");
    } else {
      setError("Something went wrong, please try again.");
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Title>Thank you!</Title>
      <Subtitle>
        Although our wedding events have come to an end, we can't wait to spend
        our lives together with all of the family and friends who made it
        happen!
      </Subtitle>
      {/* {guestData.guests.map((guest) => (
        <>
          <GuestArea>
            <Title>{guest.name}</Title>
            <Events>
              {guest.events.map((event) => {
                if (event.invited) {
                  return (
                    <EventItem>
                      <EventDate>{event.eventDate}</EventDate>
                      <EventName>{event.eventName}</EventName>
                      <Select
                        value={event.going}
                        onChange={(e) =>
                          changeSelection(
                            guestData.guests.indexOf(guest),
                            guest.events.indexOf(event),
                            e.target.value
                          )
                        }
                      >
                        <option>---</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Select>
                    </EventItem>
                  );
                } else return <></>;
              })}
              {guest?.events?.length % 2 && <EmptyEventItem />}
            </Events>
            <YesButton
              onClick={() => handleAllYes(guestData.guests.indexOf(guest))}
            >
              Yes to All
            </YesButton>
          </GuestArea>
          {guestData.guests.length > 1 ? <hr /> : []}
        </>
      ))}
      <YesButton onClick={handleConfirmRSVP} disabled={loading}>
        {loading ? <Spinner /> : "Confirm RSVP"}
      </YesButton>
      <Subtitle>{error ? error : []}</Subtitle> */}
    </Wrapper>
  );
};

export default RSVPPage;
