import React from "react";
import styled from "styled-components";
import img1 from "../images/photos/1.png";
import img2 from "../images/photos/2.png";
import img3 from "../images/photos/3.png";
import img4 from "../images/photos/4.png";
import img5 from "../images/photos/5.png";
import img6 from "../images/portcredit/3171.png";
import img7 from "../images/portcredit/3174.png";
import img8 from "../images/portcredit/3176.png";
import { colorSecondary, fontTitle, textSizeM } from "../theme";

import ImageGallery from "react-image-gallery";
import { icons } from "../icons";

const images = [
  { original: img1 },
  { original: img2 },
  { original: img3 },
  { original: img4 },
  { original: img5 },
  { original: img6 },
  { original: img7 },
  { original: img8 },
];

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const Title = styled.h2`
  font-family: ${fontTitle};
  font-size: ${textSizeM};
  font-weight: 200;
  margin-bottom: 16px;
`;

const LeftNav = styled.div`
  width: 44px;
  height: 44px;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
  svg {
    fill: white;
    filter: drop-shadow(2px 2px 3px rgb(0 0 0 / 0.5));
  }
`;

const RightNav = styled(LeftNav)`
  right: 0;
`;

const PhotosPage = () => {
  return (
    <Wrapper>
      <Title>A Chance to grow our Roots</Title>
      <ImageGallery
        items={images}
        showBullets
        renderLeftNav={(onClick, disabled) => (
          <LeftNav onClick={onClick} disabled={disabled}>
            {icons.leftArrow}
          </LeftNav>
        )}
        renderRightNav={(onClick, disabled) => (
          <RightNav onClick={onClick} disabled={disabled}>
            {icons.rightArrow}
          </RightNav>
        )}
      />
    </Wrapper>
  );
};

export default PhotosPage;
