import React from "react";
import styled from "styled-components";
import logo from "../../images/header-logo.png";
import { colorSecondary, fontMain, fontXXXS } from "../../theme";
import Logout from "../Logout";
import MobileMenu from "../MobileMenu/MobileMenu";
import Navbar from "./Navbar";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const Image = styled.img`
  width: 200px;
  height: 196px;
  cursor: pointer;
`;

const Date = styled.p`
  font-family: ${fontMain};
  font-size: ${fontXXXS};
  color: ${colorSecondary};
  margin: 0;
  padding: 0;
`;

const Header = ({ currentPage, setCurrentPage }) => {
  return (
    <Wrapper>
      <MobileMenu currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Logout />
      <Image src={logo} onClick={() => setCurrentPage("home")} />
      <Date>
        SEPTEMBER 1<sup>st</sup> - 4<sup>th</sup> 2022
      </Date>
      <Navbar currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </Wrapper>
  );
};

export default Header;
