import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getGuestData } from "../data/getGuestData";

export const GuestContext = React.createContext();

export const GuestProvider = ({ children }) => {
  const auth = getAuth();
  const [guestData, setGuestData] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const data = await getGuestData(firebaseUser.phoneNumber);
        setGuestData(data);
      } else {
        setGuestData(null);
      }
    });
  }, [auth]);

  return (
    <GuestContext.Provider value={{ guestData }}>
      {children}
    </GuestContext.Provider>
  );
};
