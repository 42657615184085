import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Navigation";
import EventPage from "./EventPage";
import RSVPPage from "./RSVPPage";
import AccommodationPage from "./AccommodationPage";
import OurStoryPage from "./OurStoryPage";
import HomePage from "./HomePage";
import PhotosPage from "./PhotosPage";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const UserPages = () => {
  const [currentPage, setCurrentPage] = useState("home");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const pages = {
    events: <EventPage setCurrentPage={setCurrentPage} />,
    rsvp: <RSVPPage />,
    accommodations: <AccommodationPage />,
    ourStory: <OurStoryPage />,
    home: <HomePage setCurrentPage={setCurrentPage} />,
    photos: <PhotosPage />,
  };

  return (
    <Wrapper>
      <Header currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {pages[currentPage]}
      <Footer />
    </Wrapper>
  );
};

export default UserPages;
