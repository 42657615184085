import React from "react";
import styled from "styled-components";
import homeimg2 from "../images/portcredit/3171b.png";
import {
  colorPrimary,
  colorSecondary,
  colorText,
  fontMain,
  fontTitle,
  textSize,
  textSizeM,
  textSizeS,
} from "../theme";

const Wrapper = styled.div`
  width: 1440px;
  max-width: 90%;
  background: ${colorSecondary};
  min-height: 60vh;
  align-self: center;
  justify-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
`;

const Title = styled.h2`
  font-family: ${fontTitle};
  font-size: ${textSizeM};
  font-weight: 200;
  margin-bottom: 16px;
  text-align: center;
`;

const Details = styled.p`
  font-family: ${fontMain};
  font-size: ${textSize};
  font-weight: 200;
  color: ${colorText};
  width: 90%;
  max-width: 700px;
  text-align: center;
`;

const HashTag = styled(Details)`
  font-size: ${textSizeS};
  margin-top: 8px;
`;

const Img = styled.img`
  width: 80vw;
  max-width: 800px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const Button = styled.div`
  padding: 8px;
  min-width: 150px;
  color: white;
  background: ${colorPrimary};
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 32px;
`;

const HomePage = ({ setCurrentPage }) => {
  return (
    <Wrapper>
      <Img src={homeimg2} loading="lazy" />
      <Title>A Chance to grow our Roots</Title>
      {/* <Button onClick={() => setCurrentPage("rsvp")}>RSVP</Button> */}
      <Details>Thank you for celebrating with us!</Details>
      <HashTag>#RutuTakesAChance</HashTag>
    </Wrapper>
  );
};

export default HomePage;
