import fourpoints from "../images/hotels/fourpoints.png";
import hilton from "../images/hotels/hilton.png";
import hiltonbrampton from "../images/hotels/hiltonbrampton.png";
import courtyard from "../images/hotels/courtyard.png";
import holidayinn from "../images/hotels/holidayinn.png";
import hampton from "../images/hotels/hampton.png";

export const mississaugaHotelData = [
  {
    imgSrc: fourpoints,
    name: "Four Points by Sheraton",
    address: "2501 Argentia Rd, Mississauga, ON L5N 4G8",
    groupRate: true,
    bookingLink:
      "https://www.marriott.com/events/start.mi?id=1654286064159&key=GRP",
    phoneNumber: "1-888-627-8135",
    phoneDetails: " | refer to Rutu & Chance Wedding Room Block",
  },
  {
    imgSrc: hilton,
    name: "Hilton Mississauga/Meadowvale",
    address: "6750 Mississauga Rd, Mississauga, ON L5N 2L3",
    groupRate: false,
    bookingLink:
      "https://www.hilton.com/en/hotels/yyzmohh-hilton-mississauga-meadowvale/?cid=OH%252CWW%252CEntNavLocations%252CMULTIPR%252CTile%252CHome%252CSingleLink%252Ci83120",
    phoneNumber: "1-905-821-1981",
    phoneDetails: "",
  },
  {
    imgSrc: courtyard,
    name: "Courtyard by Marriott",
    address: "7015 Century Ave, Mississauga, ON L5N 7K2",
    groupRate: false,
    bookingLink:
      "https://www.marriott.com/en-us/hotels/yyzms-courtyard-toronto-mississauga-meadowvale/overview/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjgxODY4MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
    phoneNumber: "1-905-567-5566",
    phoneDetails: "",
  },
  {
    imgSrc: holidayinn,
    name: "Holiday Inn & Suites",
    address: "2565 Argentia Rd, Mississauga, ON L5N 5V4",
    groupRate: false,
    bookingLink:
      "https://www.ihg.com/holidayinn/hotels/us/en/mississauga/msgon/hoteldetail?cm_mmc=GoogleMaps-_-HI-_-CA-_-MSGON",
    phoneNumber: "1-905-542-2121",
    phoneDetails: "",
  },
];

export const bramptonHotelData = [
  {
    imgSrc: hiltonbrampton,
    name: "Hilton Garden Inn Toronto/Brampton",
    address: "2648 Steeles Ave E, Brampton, ON L6S 6J9",
    groupRate: false,
    bookingLink:
      "https://www.hilton.com/en/hotels/ytobrgi-hilton-garden-inn-toronto-brampton/?SEO_id=GMB-AMER-GI-YTOBRGI&y_source=1_MjA4MzM3Ny03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
    phoneNumber: "1-905-595-5151",
    phoneDetails: "",
  },
  {
    imgSrc: hampton,
    name: "Hampton Inn by Hilton Brampton Toronto",
    address: "8710 The Gore Rd, Brampton, ON L6P 0B1",
    groupRate: false,
    bookingLink:
      "https://www.hilton.com/en/hotels/ytobmhx-hampton-brampton-toronto/?SEO_id=GMB-AMER-HX-YTOBMHX&y_source=1_MjA4NTE4MC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D",
    phoneNumber: "1-905-488-4888",
    phoneDetails: "",
  },
];
